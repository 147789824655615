import React, { useEffect, useRef, useState } from 'react';
import styles from './morphOverlay.module.css';


interface Props {
  isOpen: boolean
  closeMorph: any
}
export function MorphOverlay({isOpen, closeMorph}:Props) {

  const closeMorphSearch = () => {
    closeMorph();
  }
  // const morphSearch = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  console.log("Render isOpen: " + isOpen)
  if(isOpen){
    input.current?.focus();
    const event = new CustomEvent('animation', { detail: 1 });
    document.dispatchEvent(event);
  }
  else if (input.current)
    input.current.value = "";

  // function toggleSearch(evt: any) {
  //     // return if open and the input gets focused
  //     if( evt.type.toLowerCase() === 'focus' && isOpen ) return false;

  //     if( isOpen ) {
  //       morphSearch.current?.classList.remove("open");
  //       // classie.remove( morphSearch, 'open' );

  //       // trick to hide input text once the search overlay closes 
  //       // todo: hardcoded times, should be done after transition ends
  //       // if(input.current?.value !== '' ) {
  //       //   setTimeout(function() {
  //       //     classie.add( morphSearch, 'hideInput' );
  //       //     setTimeout(function() {
  //       //       classie.remove( morphSearch, 'hideInput' );
  //       //       input.value = '';
  //       //     }, 300 );
  //       //   }, 500);
  //       // }
        
  //       // input.current?.blur();
  //     }
  //     else {
  //       morphSearch.current?.classList.add("open");
  //       // classie.add( morphSearch, 'open' );
  //     }
  //     setIsOpen(!isOpen)
  // }

  return (
    <>
    <div id="morphsearch" className={styles.morphsearch + (" " + (isOpen ? styles.open : ""))}>
				<form className={styles.morphsearchForm + (" " + (!isOpen ? styles.hidden : ""))}>
					<input ref={input} className={styles.morphsearchInput} type="search" placeholder="type note..."/>
					<button className={styles.morphsearchSubmit} type="submit">Search</button>
				</form>
				<div className={styles.morphsearchContent}>
					<div className={styles.dummyColumn}>
						<h2>Recent</h2>
						<a className={styles.dummyMediaObject} href="#">
							<h3>No Notes</h3>
						</a>
						
					</div>
					<div className={styles.dummyColumn}>
						<h2>Urgent</h2>
						
					</div>
					<div className={styles.dummyColumn}>
						<h2>Alarms</h2>
						
					</div>
				</div>
				<span className={styles.morphsearchClose} onClick={closeMorphSearch}></span>
			</div>
      <div className={styles.overlay}></div>
    </>
  )
}