import React, { useEffect, useRef, useState } from 'react';

import { MorphOverlay } from '../morphOverlay/morphOverlay';
const ESCAPE_KEYS = ["27", "Escape"];


const useEventListener = (eventName:any, handler:any, element = window) => {
  const savedHandler = useRef<any>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = (event:any) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};

export function Content() {
  const [morphOpened, setMorphOpened] = useState(false);

  const onClick = () => {
    setMorphOpened(!morphOpened);
  }

  const closeMorphSearch = () => {
    setMorphOpened(false);
    const event = new CustomEvent('animation', { detail: 0 });
    document.dispatchEvent(event);
  }

  const handler = ({ key }:any) => {
    if (ESCAPE_KEYS.includes(String(key))) {
      console.log("Escape key pressed!");
      if(morphOpened)
        closeMorphSearch();
    }
    else{
      if(!morphOpened){
        setMorphOpened(true);
      }
    }
  };

  useEventListener("keydown", handler);

  return (
      <>
      <header className="App-header">
        <h1 onClick={onClick} id="donna-header">donna</h1>
      </header>
      <MorphOverlay isOpen={morphOpened} closeMorph={closeMorphSearch}/>

      </>
  )
}