import Typed from 'react-typed';

export function Voice(){
  const changeAnim = () => {
    const event = new CustomEvent('animation', { detail: 2 });
    document.dispatchEvent(event);
  }
  return (
    <div className='voice'>
      <Typed
                    strings={['^2000Hello.^3000', 'My name is donna. ^1000<br>I\'m your digital prospective memory.^3000', 'I dont know yet who you are...^3000<br>Signup now or let me introduce myself first.^3000', 'I\'m here to help you to not forget anymore.^3000', 'Click on my name or start typing!^3000']}
                    typeSpeed={50}
  									backSpeed={0}
                    onComplete={changeAnim}
                />
    </div>
  )
}