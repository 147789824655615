import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import { Content } from './features/content/Content';
import { Voice } from './features/voice/Voice';

function App() {
  
  return (
    <div className="App">
      <Content></Content>
      <Voice />
    </div>
  );
}

export default App;
